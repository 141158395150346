// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-all {
  /* font-family: 'Courier New', monospace; */
  /* font-family: 'Arial', sans-serif; */
  /* font-family: 'Times New Roman', serif; */
  /* font-family: 'Helvetica', sans-serif; */
  /* font-family: 'Georgia', serif; */
  /* font-family: 'Verdana', sans-serif; */
  /* font-family: 'Tahoma', sans-serif; */
  /* font-family: 'Calibri', sans-serif; */
  /* font-family: 'Arial Black', sans-serif; */
  /* font-family: 'Comic Sans MS', cursive; */
  /* font-family: 'Impact', sans-serif; */
  /* font-family: 'Lucida Console', monospace; */
  /* font-family: 'Palatino Linotype', serif; */
  font-family: 'Trebuchet MS', sans-serif;
  /* font-family: 'Century Gothic', sans-serif; */
  /* font-family: 'Garamond', serif; */
  /* font-family: 'Book Antiqua', serif; */
  /* font-family: 'Copperplate', fantasy; */
  /* font-family: 'Franklin Gothic Medium', sans-serif; */
  /* font-family: 'Geneva', sans-serif; */
  /* font-family: 'Optima', sans-serif; */
  box-sizing: border-box; 


}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,sCAAsC;EACtC,2CAA2C;EAC3C,0CAA0C;EAC1C,mCAAmC;EACnC,wCAAwC;EACxC,uCAAuC;EACvC,wCAAwC;EACxC,4CAA4C;EAC5C,2CAA2C;EAC3C,uCAAuC;EACvC,8CAA8C;EAC9C,6CAA6C;EAC7C,uCAAuC;EACvC,+CAA+C;EAC/C,oCAAoC;EACpC,wCAAwC;EACxC,yCAAyC;EACzC,uDAAuD;EACvD,uCAAuC;EACvC,uCAAuC;EACvC,sBAAsB;;;AAGxB","sourcesContent":[".font-all {\n  /* font-family: 'Courier New', monospace; */\n  /* font-family: 'Arial', sans-serif; */\n  /* font-family: 'Times New Roman', serif; */\n  /* font-family: 'Helvetica', sans-serif; */\n  /* font-family: 'Georgia', serif; */\n  /* font-family: 'Verdana', sans-serif; */\n  /* font-family: 'Tahoma', sans-serif; */\n  /* font-family: 'Calibri', sans-serif; */\n  /* font-family: 'Arial Black', sans-serif; */\n  /* font-family: 'Comic Sans MS', cursive; */\n  /* font-family: 'Impact', sans-serif; */\n  /* font-family: 'Lucida Console', monospace; */\n  /* font-family: 'Palatino Linotype', serif; */\n  font-family: 'Trebuchet MS', sans-serif;\n  /* font-family: 'Century Gothic', sans-serif; */\n  /* font-family: 'Garamond', serif; */\n  /* font-family: 'Book Antiqua', serif; */\n  /* font-family: 'Copperplate', fantasy; */\n  /* font-family: 'Franklin Gothic Medium', sans-serif; */\n  /* font-family: 'Geneva', sans-serif; */\n  /* font-family: 'Optima', sans-serif; */\n  box-sizing: border-box; \n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
