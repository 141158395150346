import React, { useState } from 'react';
import './Verification.css'; // Importing CSS file for styling
import axios from 'axios';

const Verification = () => {
  const backend_url = process.env.REACT_APP_API_PATH;
  const chatapp_url = process.env.REACT_APP_CHATAPP_API_PATH;

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default country code
  const [error, setError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!email || !phone) {
        setError('Please enter both email and phone number.');
        return;
      }

      // If validation passes, proceed with form submission
      setError('');
      const fullPhoneNumber = countryCode + phone; // Combine country code with phone number

      const response = await axios.post(`${backend_url}/api/verification`, {
        email,
        phone: fullPhoneNumber,
      });

      if (response.status === 201) {
        window.location.href = `${chatapp_url}/${response.data._id}`;
      } else {
        setError('Error submitting form. Please try again later.');
      }
    } catch (error) {
      if (error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Internal server error. Please try again later');
      }
    }
  };

  return (
    <div className="verification-page">
      <div className="verification-top">
        <h1>Nova AI</h1>
      </div>
      <div className="verification-container">
        <div className="verification-form">
          {/* <h1>Nova AI</h1>  */}
          <form onSubmit={handleFormSubmit}>
            <div className="input-group">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email address"
              />
            </div>
            <div className="input-group">
              <label>Phone:</label>
              <div className="phone-input">
                <select value={countryCode} onChange={handleCountryCodeChange}>
                  <option value="+91">+91 (India)</option>
                  {/* Add more country codes as needed */}
                </select>
                <input
                  type="tel"
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Enter your phone number"
                />
              </div>
            </div>
            <div className="button">
            <button type="submit">Submit</button>
            </div>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verification;
