// AboutUs.jsx
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './AboutUs.css'

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="abt-container">
        <div className="abt-content">
          <h1 className='abt'>We are a stealth mode AI Startup founded by techies who have worked across global fortune 50 companies.</h1>
          <h1>Our core strength is AI and we are working with Enterprises to help them build Customized Gen AI Apps, Chatbots on their domain specific data.</h1>
          <h1>Do try us at <a href="https://www.novakrutrim.com">www.novakrutrim.com</a>, sign up for beta and try our chat bot built on fine tuned LLaMa.</h1>
          <h1>We want to further collaborate with other startup/enterprise clients to help them leverage the power of Gen AI.</h1>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
