// Navbar.js

import React, { useState } from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  const [showNavLinks, setShowNavLinks] = useState(false);

  const toggleNavLinks = () => {
    setShowNavLinks(!showNavLinks);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        {/* <img src="" alt="Logo" className="logo" /> */}
        <span className="brand-name" onClick={()=>navigate('/')}>
          Nova AI
        </span>
      </div>
      <div className="navbar-right">
        <div className="hamburger" onClick={toggleNavLinks}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <ul className={showNavLinks ? 'nav-links show' : 'nav-links'}>
          <li>
            <a href="#platform">Engage AI</a>
          </li>
          <li>
            <a href="#company">Prediktor</a>
          </li>
          <li>
            <a href="#contact">Nova AI</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
