import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importing useHistory hook for programmatic navigation
import './Main.css'; // Importing CSS file for styling
import ReactGA4 from "react-ga4";

const Landing = () => {
  const navigate = useNavigate();

  // Function to handle click on the "Signup For Beta" button
  const handleClick = () => {
    ReactGA4.event({
      action: 'button_clicked  homepage',
      category: 'User Interaction homepage',
      label: 'My Button Label homepage',
    });
    navigate('/verification');
  };

  return (
    <div className="landing-container">
      
      <div className="landing-content">
        <h1 className="heading">Nova AI</h1>
        <p className="subheading">Bharat's own Artificial Intelligence</p>
        <p className="description">
          Experience cutting-edge AI technology developed by experts from India.
          Our AI solutions are tailored to meet your needs and drive innovation.
        </p>
        {/* Use onClick to trigger handleClick function */}
        <button className="join-button" onClick={handleClick}>
          Signup For Beta
        </button>
      </div>
      <footer className="footer">
        <div className="footer-left">
        <div className='footer-name'>Nova AI</div>
        <div>For any queries contact us on: connect@novakrutrim.com</div>
        </div>
        <div className="footer-right">Made in India</div>
      </footer>
    </div>
  );
};

export default Landing;
