import React, { useState } from 'react';
import axios from 'axios';
import './Voice.css';

const Voice = () => {
  const backend_url = process.env.REACT_APP_API_PATH;

  const [audioSrc, setAudioSrc] = useState(null);
  const [text, setText] = useState('');

  const handleAudioUpload = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    if (file) {
      formData.append('voice_file', file);
      const url = URL.createObjectURL(file);
      setAudioSrc(url);
      const response = await axios.post(
        `${backend_url}/api/verification`,
        formData
      );
      // Assuming you have a function to convert voice to text
      convertVoiceToText(file).then((transcribedText) => {
        setText(transcribedText);
      });
    }
  };

  const convertVoiceToText = async (file) => {
    // Placeholder function for voice to text conversion
    // Replace this with actual implementation
    return 'Transcribed text goes here...';
  };

  return (
    <div className="voiceContainer">
      <div className="voiceCard voiceLeft">
        <h2 className="voiceHeading">Upload Voice</h2>
        <input
          type="file"
          accept="audio/*"
          onChange={handleAudioUpload}
          className="voiceUploadButton"
        />
        {audioSrc && (
          <audio controls src={audioSrc} className="voiceAudioPlayer"></audio>
        )}
      </div>
      <div className="voiceCard voiceMiddle">
        <h2 className="voiceHeading">Voice to Text</h2>
        <div className="voiceTextContent">{text}</div>
      </div>
      <div className="voiceCard voiceRight">
        <h2 className="voiceHeading">Analysis</h2>
        <div className="voiceAnalysisContent">
          {/* Add analysis content here */}
        </div>
      </div>
    </div>
  );
};

export default Voice;
