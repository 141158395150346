import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import Verification from './pages/Verification';
import ReactGA4 from 'react-ga4';
import './App.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Voice from './pages/Voice';

const initializeGA4 = async () => {
  try {
    console.log(process.env.REACT_APP_MEASUREMENT_ID);
    await ReactGA4.initialize(process.env.REACT_APP_MEASUREMENT_ID);
  } catch (error) {
    console.error('Failed to initialize GA4:', error);
  }
};

const App = () => {
  useEffect(() => {
    initializeGA4();
  }, []);
  return (
    <div className="font-all">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/landing" element={<Landing />} /> */}

          <Route path="/verification" element={<Verification />} />
          <Route path="/about" element={<AboutUs />} />

          <Route path="/voice" element={<Voice />} />

        </Routes>
      </Router>
    </div>
  );
};

export default App;
