import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const redirectToLinkedIn = () => {
    window.open('https://www.linkedin.com/company/nova-krutrim/', '_blank');
  };
  return (
    <footer className="footer">
      <div className="footer-left">
        {/* <img src="/dp.png" alt="Company Logo" /> */}
        <div className="company-info">
          <h2>Nova AI</h2>
          <p>Building AI stack for India</p>
        </div>
      </div>
      <div className="footer-right">
        <div className="product-list">
          <h4>Products</h4>
          <ul>
            <li>Engage AI</li>
            <li>Prediktor</li>
            <li>Nova AI</li>
          </ul>
        </div>
        <div className="company-links">
          <h4>Company</h4>
          <ul>
            <li onClick={()=>navigate('/about')}>About Us</li>
            <li onClick={redirectToLinkedIn}>LinkedIn</li>
            <li>Life at Nova AI</li>
          </ul>
        </div>
        <div className="contact-info">
          <h4>Contact Us</h4>
          <ul>
            <li>connect@novakrutrim.com</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
