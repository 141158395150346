import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

const Home = () => {
  const navigate = useNavigate();

  // Function to handle click on the "Signup For Beta" button
  const handleClick = () => {
    ReactGA4.event({
      action: 'button_clicked  homepage',
      category: 'User Interaction homepage',
      label: 'My Button Label homepage',
    });
    window.location.href = 'https://chatapp.novakrutrim.com';
  };
  return (
    <>
      <Navbar />
      <div className="home-container">
        <div className="home-left">
          <div className="first-line">
            Unleash the Power of Gen AI for enterprises
          </div>
          <div className="second-line">
            Transform your Organization AI Capabilities with Nova AI Gen AI
            Platforms and Services.
          </div>
          <div className="button-center">
            <button className="signUpButton" onClick={handleClick}>
              Signup For Beta
            </button>
          </div>
        </div>
        <div className="home-right">
          <video className="video-background" autoPlay loop muted>
            <source src="/Banner.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div class="product-container">
        <div class="heading">
          <h1>Products</h1>
        </div>
        <div class="card-container">
          <div class="card">
            <img src="/engageAI_Logo.png" alt="" />
            <h1>Engage AI</h1>
            <h2>Generative AI simplified and transformed</h2>
            <p>
              An Custom AI Content Generation Platform, which can be used to
              generate Engaging Social Media Campaigns, Brand Voices, Generative
              Art, Effective Emails, SEO Friendly Website Content, PRD Document
              etc.
            </p>
            <button>Know More</button>
          </div>
          <div class="card">
            <img src="/LOGO.png" alt="" />
            <h1>Prediktor</h1>
            <h2>Generative AI simplified and transformed</h2>
            <p>
              A self help data visualization tool which can be used by any
              organization to visualize the data.
            </p>
            <button>Know More</button>
          </div>
          <div class="card">
            <img src="/novaLogo.png" alt="" />
            <h1>Nova AI</h1>
            <h2>Building AI stack for India</h2>
            <p>Chatbot built  by finetuning LLaMa.</p>
            <button>Know More</button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
