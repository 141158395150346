// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AboutUs.css */

.abt-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.abt-content {
  flex-grow: 1;
  text-align: center;
}

.abt-content h1 {
  margin: 20px 0; /* Adjust margin for all screen sizes */
  font-size: 2rem; /* Default font size */
}

.abt-content a {
  color: #007bff;
  text-decoration: underline;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .abt-content h1 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 992px) {
  .abt-content h1 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .abt-content h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 576px) {
  .abt-content h1 {
    font-size: 1.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc,EAAE,uCAAuC;EACvD,eAAe,EAAE,sBAAsB;AACzC;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA,sBAAsB;AACtB;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["/* AboutUs.css */\n\n.abt-container {\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n}\n\n.abt-content {\n  flex-grow: 1;\n  text-align: center;\n}\n\n.abt-content h1 {\n  margin: 20px 0; /* Adjust margin for all screen sizes */\n  font-size: 2rem; /* Default font size */\n}\n\n.abt-content a {\n  color: #007bff;\n  text-decoration: underline;\n}\n\n/* Responsive styles */\n@media screen and (max-width: 1200px) {\n  .abt-content h1 {\n    font-size: 1.8rem;\n  }\n}\n\n@media screen and (max-width: 992px) {\n  .abt-content h1 {\n    font-size: 1.6rem;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .abt-content h1 {\n    font-size: 1.4rem;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .abt-content h1 {\n    font-size: 1.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
